// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-generators-admin-resource-index-js": () => import("./../../../src/Generators/AdminResource/index.js" /* webpackChunkName: "component---src-generators-admin-resource-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-detail-index-js": () => import("./../../../src/Templates/BlogDetail/index.js" /* webpackChunkName: "component---src-templates-blog-detail-index-js" */),
  "component---src-templates-consult-dashboard-index-js": () => import("./../../../src/Templates/ConsultDashboard/index.js" /* webpackChunkName: "component---src-templates-consult-dashboard-index-js" */),
  "component---src-templates-digital-transformation-index-js": () => import("./../../../src/Templates/DigitalTransformation/index.js" /* webpackChunkName: "component---src-templates-digital-transformation-index-js" */),
  "component---src-templates-landing-index-js": () => import("./../../../src/Templates/Landing/index.js" /* webpackChunkName: "component---src-templates-landing-index-js" */),
  "component---src-templates-web-2-web-3-index-js": () => import("./../../../src/Templates/Web2Web3/index.js" /* webpackChunkName: "component---src-templates-web-2-web-3-index-js" */)
}

